export const photos = [
	{ id: 0, src: '/images/1.jpg', width: 4, height: 3 },
	{ id: 1, src: '/images/2.jpg', width: 2, height: 3 },
	{ id: 2, src: '/images/3.jpg', width: 5, height: 3 },
	{ id: 3, src: '/images/4.jpg', width: 3, height: 3 },
	{ id: 4, src: '/images/5.jpg', width: 4, height: 3 },
	{ id: 5, src: '/images/6.jpg', width: 4, height: 3 },
	{ id: 6, src: '/images/7.jpg', width: 4, height: 3 },
	{ id: 7, src: '/images/8.jpg', width: 4, height: 3 },
	{ id: 8, src: '/images/9.jpg', width: 4, height: 3 },
	{ id: 9, src: '/images/10.jpg', width: 4, height: 3 },
	{ id: 10, src: '/images/12.jpg', width: 4, height: 3 },
	{ id: 11, src: '/images/13.jpg', width: 4, height: 3 },
	{ id: 12, src: '/images/14.jpg', width: 4, height: 3 },
	{ id: 13, src: '/images/15.jpg', width: 4, height: 3 },
	{ id: 14, src: '/images/16.jpg', width: 4, height: 3 },
	{ id: 15, src: '/images/17.jpg', width: 4, height: 3 },
	{ id: 16, src: '/images/18.jpg', width: 4, height: 3 },
	{ id: 17, src: '/images/19.jpg', width: 4, height: 3 },
	{ id: 18, src: '/images/20.jpg', width: 4, height: 3 },
	{ id: 19, src: '/images/35.jpg', width: 4, height: 3 },

	{ id: 20, src: '/images/31.jpg', width: 4, height: 3 },
	{ id: 21, src: '/images/21.jpg', width: 4, height: 3 },
	{ id: 22, src: '/images/46.jpg', width: 4, height: 3 },
	{ id: 23, src: '/images/47.jpg', width: 4, height: 3 },
	{ id: 24, src: '/images/istanbul25.jpg', width: 4, height: 3 },
];
